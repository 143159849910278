import { Button, Group } from "@mantine/core";
import AddNewOutcome from "./AddNewOutcome";
import useMiniDyiStore from "./miniDYIStore";
import makeKey from "../../utils/makeKey";
import { useTranslation } from "react-i18next";

function CustomOutComeStep({readOnly}: {readOnly?: boolean}) {
  const selectedOutcomes = useMiniDyiStore((state) => state.selectedOutcomes);
  const customOutcomes = useMiniDyiStore((state) =>
    state.selectedOutcomes.filter((so) => !so.outcomeId)
  );
  const { t } = useTranslation();
  const addCustomOutcome = useMiniDyiStore((state) => state.addCustomOutcome);
  return (
    <>
      {selectedOutcomes
        .filter((so) => typeof so.sdg == "undefined")
        .map((outcome) => {
          return <AddNewOutcome key={outcome.outcomeId} outcome={outcome} disabled={readOnly}/>;
        })}
      <Group position="center" mt="md">
        <Button
          onClick={() => {
            console.log(customOutcomes);
            addCustomOutcome({
              customKey: makeKey(12),
              outcomeName: "",
              stakeholderName: "",
              indicators: [
                {
                  customKey: makeKey(12),
                  indicatorName: "",
                  indicatorTargetValue: 0,
                  proxyName: "",
                  proxyValue: 0,
                },
              ],
            });
          }}
          disabled={customOutcomes.length == 3 || readOnly}
        >
          {t("components.mini-dyi.add-custome-outcome")}
        </Button>
      </Group>
    </>
  );
}

export default CustomOutComeStep;
