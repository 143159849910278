import { useEffect, useState } from "react";
import { FillableComponentProps } from "../pages/calls/stepsConfig";
import UploadFile from "./UploadFile";
import {ExtraInfoAllegatoResult} from "../api/model";
import {mutate} from "swr";
import {
 getGetApiCallGetSubmissionByIDKey
} from "../api/endpoints";

export type FileContent = {
  file_name: string,
  file_description: string,
  file_type: string,
  dimensions: number,
  mandatory: false,
}

export const UploadList = ({
  submission,
  data,
  step,
  setData
}: FillableComponentProps) => {
  console.log("upload content",step.content)
  console.log("upload content data",data)
  console.log("upload content step",step)
  console.log("upload content submission",submission)

  

  const [stepFiles, setStepFiles ] = useState<FileContent[]>(step.content && step.content != ""  && JSON.parse(step.content!) ? JSON.parse(step.content!) : []);
  const [stepContentData, setStepContentData ] = useState<ExtraInfoAllegatoResult[]>( () => { return data && data != ""  && JSON.parse(data!) ? JSON.parse(data) : [];});


  useEffect( () => {
    setData(JSON.stringify(stepContentData)).then((res)=>{
        console.log('useEffect -> setData',res);
        const submissionId = res.callSubmissionId
        
    });
    
  },[stepContentData])

  return (
    <>
      {stepFiles.map((e, i) => {
        return (
          <>
            <UploadFile 
                content={e} 
                key={i} 
                data={stepContentData[i]} 
                setData={async (value: ExtraInfoAllegatoResult)=>{
                  setStepContentData(stepFiles.map((file, idx) => {
                    if (idx === i) return value;
                    return stepContentData[idx]
                  }));
                }}
             />
          </>
        )
      })}
    </>
  )
}
