import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth, useRefreshToken } from "../auth/AuthContext";
import useSelectedOrganizationId from "../hooks/useSelectedOrganizationId";

const LogoutCallback = () => {
  const { setAccessToken } = useAuth();
  const [_, setRefreshToken] = useRefreshToken();
  const [selectedOrgId, setSelectedOrgId] = useSelectedOrganizationId();
  // const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("LOGGING OUT");
    setAccessToken(null);
    setRefreshToken(null, 30);
    setSelectedOrgId(null);
  });
  return <Navigate to="/" state={{ from: location }} replace />;
};
export default LogoutCallback;
